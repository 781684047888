<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">上传设置</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :model="form" label-width="160px" style="max-width: 750px;">
				<el-form-item label="默认上传方式">
					<el-radio v-model="form.default" value="local" label="local">
						本地（不推荐）
					</el-radio>
					<el-radio v-model="form.default" value="qiniu" label="qiniu">
						七牛云存储
					</el-radio>
					<el-radio v-model="form.default" value="aliyun" label="aliyun">
						阿里云
					</el-radio>
					<el-radio v-model="form.default" value="qcloud" label="qcloud">
						腾讯云
					</el-radio>
					</el-radio-group>
				</el-form-item>
				<template v-if="form.default == 'qiniu'">
					<el-form-item label="存储空间名称 Bucket">
						<el-input v-model="form.engine.qiniu.bucket"></el-input>
					</el-form-item>
					<el-form-item label="ACCESS_KEY AK">
						<el-input v-model="form.engine.qiniu.access_key"></el-input>
					</el-form-item>
					<el-form-item label="SECRET_KEY SK">
						<el-input v-model="form.engine.qiniu.secret_key"></el-input>
					</el-form-item>
					<el-form-item label="空间域名 Domain">
						<el-input v-model="form.engine.qiniu.domain"></el-input>
					</el-form-item>
				</template>
				<template v-if="form.default == 'aliyun'">
					<el-form-item label="存储空间名称 Bucket">
						<el-input v-model="form.engine.aliyun.bucket"></el-input>
					</el-form-item>
					<el-form-item label="AccessKeyId">
						<el-input v-model="form.engine.aliyun.access_key_id"></el-input>
					</el-form-item>
					<el-form-item label="AccessKeySecret">
						<el-input v-model="form.engine.aliyun.access_key_secret"></el-input>
					</el-form-item>
					<el-form-item label="空间域名 Domain">
						<el-input v-model="form.engine.aliyun.domain"></el-input>
					</el-form-item>
				</template>
				<template v-if="form.default == 'qcloud'">
					<el-form-item label="存储空间名称 Bucket">
						<el-input v-model="form.engine.qcloud.bucket"></el-input>
					</el-form-item>
					<el-form-item label="所属地域 Region">
						<el-input v-model="form.engine.qcloud.region"></el-input>
					</el-form-item>
					<el-form-item label="SecretId">
						<el-input v-model="form.engine.qcloud.secret_id"></el-input>
					</el-form-item>
					<el-form-item label="SecretKey">
						<el-input v-model="form.engine.qcloud.secret_key"></el-input>
					</el-form-item>
					<el-form-item label="空间域名 Domain">
						<el-input v-model="form.engine.qcloud.domain"></el-input>
					</el-form-item>
				</template>
				<el-form-item>
					<el-button type="primary" @click="submit" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				loading: false,
				form: {
					default: 'local',
					engine: {
						local: {},
						qiniu: {
							bucket: '',
							access_key: '',
							secret_key: '',
							domain: 'http://'
						},
						aliyun: {
							bucket: '',
							access_key_id: '',
							access_key_secret: '',
							domain: 'http://'
						},
						qcloud: {
							bucket: '',
							region: '',
							secret_id: '',
							secret_key: '',
							domain: 'http://'
						}
					}
				},
			}
		},
		created() {
			this.getSettingStorage();
		},
		methods: {
			getSettingStorage() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/setting/storage',
					},
					method: 'get',
				}).then(res => {
					this.form = res.data.data
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},

			// 提交
			submit() {
				this.loading = true;
				this.axios({
					token: true,
					params: {
						s: 'store/setting/storage',
					},
					data: {
						storage: this.form
					},
					method: 'post',
				}).then(res => {
					if (res.data.code == 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						// 跳转后台首页
						this.$router.push({
							name: 'setting/storage'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
					this.loading = false;
				}).catch(err => {
					this.loading = false;
				})
			},
		},
	}
</script>

<style>

</style>
